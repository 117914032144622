<template>
  <PageLoader v-if="pageLoading" :loading="pageLoading"> </PageLoader>

  <div v-else class="container_margin">
    <div class="row">
      <div class="col-12 col-md-7 mt-2">
        <div class="row">
          <div class="col">
            <h4 class="float-left text-blue mt-2">
              <b>{{ "Full Contract" }}</b>
            </h4>

            <button
              v-if="contractType === 'html'"
              @click="saveContractChanges"
              class="float-right btn btn-md btn-primary"
            >
              {{ "Save Changes" }}
            </button>

            <button
              v-if="contractType === 'html'"
              @click="exportData('DOCUMENT')"
              class="btn-primary-outlined btn btn-md px-2 float-right mx-2"
            >
              {{ "Export" }}
            </button>
          </div>
        </div>

        <div class="card shadow rounded p-2 mt-3 card_caseDoc">
          <span
            v-if="contractType === 'html'"
            id="htmlContentEditor"
            @click="clickCREBlock($event)"
            v-html="htmlArray.map((block) => block.content).join('')"
          >
          </span>

          <iframe
            v-else-if="contractType === 'pdf'"
            id="pdf-iframe"
            :src="this.htmlContent"
            height="660px"
            width="100%"
            frameborder="0"
          ></iframe>
        </div>
      </div>

      <div class="col-12 col-md-5 mt-2">
        <h4 class="text-center text-blue mt-2">
          <b>{{ "AI Suggestions & LawBot" }}</b>
        </h4>

        <div class="card shadow rounded p-4 mt-4 card_bot_question">
          <div class="d-flex mb-3">
            <div class="tabs__folderTabs mt-auto">
              <button
                @click="selectedTab = 'aiSug'"
                style="margin-right: .5rem;"
                :class="{
                  folderTab: selectedTab === 'aiSug',
                  active: selectedTab === 'aiSug',
                }"
              >
                <span class="folderTab__title">{{ "AI Suggestions" }}</span>
              </button>
              <button
                v-if="permissions.includes('nexlaw.qbot')"
                @click="selectedTab = 'qBot'"
                :class="{
                  folderTab: selectedTab === 'qBot',
                  active: selectedTab === 'qBot',
                }"
              >
                <span class="folderTab__title">
                  {{ "LawBot" }}
                  <b-icon
                    v-if="selectedTab !== 'qBot'"
                    icon="stars"
                    class="mx-1"
                    font-scale="1.3"
                    :animation="selectedTab !== 'qBot' ? 'fade' : 'none'"
                  />
                </span>
              </button>
            </div>
          </div>

          <div
            v-if="selectedTab === 'qBot'"
            class="card border-0 card_question"
          >
            <div class="chat__box" id="messages" ref="messages1">
              <div
                v-for="(message, idx) in chatMessages"
                :key="idx"
                :class="message.class_1"
              >
                <div :class="message.class_1">
                  <div :class="message.class_3">
                    <span v-html="
                      message.text
                        .replace(/\\n*/g, '<br>')
                        .replace(/\\n\\n*/g, '<br>')
                        .replace(/\\n\\n/g, '<br>')
                        .replace(/\\n/g, '<br>')
                        .replace(/\n/g, '<br>')
                        .replace(/\n\n*/g, '<br>')
                        .replace('*', '')
                        .replace(/\\/g, '<br>')
                    "></span>
                  </div>
                </div>
              </div>

              <div v-if="loadAnswer" class="chat__incoming">
                <div class="chat__bubble__wrapper-incoming">
                  <div class="chat__bubble-incoming animated-background-incoming">
                    <div
                      style="transform: scale(0.7)"
                      class="circles-to-rhombuses-spinner"
                    >
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="chat__suggestion"
              v-for="(question, index) in default_questions.slice(0, 3)"
              :key="index"
              @click="chooseMessage(question, index)"
              :disabled="loadAnswer || question.displayed"
            >
              <span>{{ question.text }}</span>
            </button>

            <div class="chat__input">
              <input
                :disabled="loadAnswer"
                @keyup.enter="sendNewMessage"
                type="text"
                v-model="NewText"
                placeholder="Ask Questions about your Contract..."
              />
              <button
                @click="sendNewMessage"
                :disabled="loadAnswer"
                class="btn-send"
              >
                <span class="material-icons-sharp">arrow_circle_right</span>
              </button>
            </div>
          </div>

          <template v-else-if="selectedTab === 'aiSug'">
            <h5 class="mt-2"><b>List of Suggestions to Improve your Contract</b></h5>
            
            <template v-if="contractType === 'html'">
              <p class="text-muted font-14 my-0 mb-3">
                Click on <b class="text-blue">Edit Clause</b>
                to enhance the suggested clause or to generate a new clause
              </p>

              <div
                v-for="block in htmlArray.filter((blk) => blk.clause)"
                :key="`sug-${block.id}`"
                v-show="block.found"
                :class="[
                  'card my-2 p-2 cursor-pointer',
                  { 'highlight-card': block.id === currentBlock },
                ]"
                @click="clickSuggestionCard(block.id)"
              >
                <h6><b>Original Clause :</b></h6>
                <p v-html="block.clause.text"></p>

                <h6><b>Suggestions :</b></h6>
                <p
                  v-html="
                    block.clause.sug
                      .replace(/\\n*/g, '<br>')
                      .replace(/\\n\\n*/g, '<br>')
                      .replace(/\n/g, '<br>')
                      .replace(/\n\n*/g, '<br>')
                      .replace(/\\n\\n/g, '<br>')
                      .replace(/\\n/g, '<br>')
                      .replace('*', '')
                      .replace(/\\/g, '<br>')
                  "
                ></p>

                <div class="row">
                  <div class="col">
                    <button
                      @click.stop="accessAISuggestions(block.id)"
                      class="text-primary font-14 float-right"
                    >
                      <b>Edit Clause</b>
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <template v-else-if="contractType === 'pdf'">
              <div
                v-for="(sugg, index) in htmlArray"
                :key="index"
                v-show="sugg"
                class="card my-1 p-2 cursor-pointer"
                @click="clickSuggestionCard(index)"
              >
                <h6><b>Original Clause :</b></h6>
                <p v-html="sugg.Clause"></p>

                <h6><b>Suggestions :</b></h6>
                <p v-html="
                  sugg.Suggestions.replace(/\\n*/g, '<br>')
                    .replace(/\\n\\n*/g, '<br>')
                    .replace(/\n/g, '<br>')
                    .replace(/\n\n*/g, '<br>')
                    .replace(/\\n\\n/g, '<br>')
                    .replace(/\\n/g, '<br>')
                    .replace('*', '')
                    .replace(/\\/g, '<br>')
                "></p>
              </div>
            </template>
          </template>

          <template v-else-if="selectedTab === 'showSuggestions'">
            <div class="row mt-2">
              <div class="col">
                <h6>
                  <b>{{ contractType === "html"
                    ? "Improved Clause"
                    : "Suggestion" }}</b>
                </h6>
                <p class="font-14 mb-0 text-muted">Text Editor</p>
              </div>
              <div class="col-auto">
                <button
                  @click="backtosuggestions"
                  class="text-muted float-right font-14"
                >
                  &#8592; Back to Suggestions List
                </button>
              </div>
            </div>
            <div>
              <textarea
                v-if="contractType === 'html'"
                class="form-control mt-1"
                rows="18"
                maxrow="18"
                @input="replaceContent('mod')"
                v-model="modifiedContent"
                autocomplete="off"
              ></textarea>
            </div>

            <div class="d-flex align-items-center mt-4">
              <b>{{ "Generate New Customized Clause" }}</b>
              <span
                id="tooltip-target-1"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-1"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="CRTooltip"
              >
                Optional
              </b-tooltip>
            </div>

            <PageLoader
              v-if="isGenerating[currentBlock] ?? false"
              :loading="isGenerating[currentBlock] ?? false"
              replace_msg="Generating"
            ></PageLoader>

            <div v-else-if="!isGenerating[currentBlock]">
              <b-form-textarea
                id="textarea"
                class="mt-1"
                v-model="suggestionPrompt"
                placeholder="Enter prompt to generate new customized clause to replace current improved clause"
                autocorrect="off"
                autocomplete="off"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>

            <div v-if="!isGenerating[currentBlock]" class="row mt-2">
              <div class="col">
                <button
                  class="btn-primary btn px-2 float-right"
                  @click="generateContent"
                >
                  {{ "Generate" }}
                </button>
              </div>
            </div>

            <template v-if="currentGenEg.length != 0">
              <h6 class="mt-4 mb-0">
                <b>{{ "Customized Clause" }}</b>
              </h6>
              <p class="font-14 text-muted">
                Click to replace its respective clause
              </p>

              <div class="input-group">
                <div class="form-check ms-1">
                  <input
                    class="form-check-input"
                    type="radio"
                    :value="'exampleCurr-' + currentBlock"
                    :name="'exampleCurr-' + currentBlock"
                    :id="'exampleCurr-' + currentBlock"
                    v-model="egVar"
                    @change="replaceContent('new')"
                    :disabled="contractType === 'pdf'"
                  />
                  <label
                    class="form-check-label cursor-pointer"
                    :for="'exampleCurr-' + currentBlock"
                  >
                    {{ currentGenEg }}
                  </label>
                </div>
              </div>
            </template>

            <template v-if="currentBlockObj.clause.imp.length > 0">
              <h6 class="mt-4 mb-0">
                <b>{{ "Previous Customized Clause(s)" }}</b>
              </h6>
              <p class="font-14 text-muted">
                Click to replace its respective clause
              </p>
            </template>

            <div
              class="input-group"
              v-for="(item, idx) in currentBlockObj?.clause.imp"
              :key="'imp' + idx"
            >
              <div class="form-check ms-1">
                <input
                  class="form-check-input"
                  type="radio"
                  :value="idx"
                  :name="'example-' + idx"
                  :id="idx"
                  v-model="egVar"
                  @change="replaceContent('imp', idx)"
                  :disabled="contractType === 'pdf'"
                />
                <label
                  class="form-check-label cursor-pointer"
                  :for="idx"
                >
                  {{ item }}
                </label>
              </div>
            </div>

            <button
              v-if="(currentBlockObj.clause.imp.length > 0 || currentGenEg)"
              class="btn btn-primary px-2 float-right mt-3"
              @click="replaceContent('undo')"
            >
              {{ "Undo" }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import ContractReview from "@/store/ContractReview.js";
import Auth from "@/store/Auth.js";

function escapeRegExp(string) {
  if (typeof string !== "string") return string;
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      selectedTab: "aiSug",
      contractType: "",
      case_file: "",
      isGenerating: {},
      loadAnswer: false,
      pageLoading: true,
      chosenPage: "",
      pages: [],
      NewText: "",
      status: false,
      default_questions: [
        { text: "List down key points in the contract.", displayed: false },
        {
          text: "Does the contract comply with my policy: The dispute resolution is under Australian Jurisdiction?",
          displayed: false,
        },
        {
          text: "Does the contract comply with my policy: The employment terms apply to everyone regardless of gender?",
          displayed: false,
        },
        {
          text: "Add 'Unforeseen Circumstances' in Definition clause.",
          displayed: false,
        },
      ],
      pdf_status: [],
      chatMessages: [],
      entered: false,
      expanded: false,
      contract_improvments_full: JSON.parse(localStorage.userInfo)
        .nexlaw_credits.full.contract_improvments,
      contract_improvments_remain:
        JSON.parse(localStorage.userInfo).nexlaw_credits.full
          .contract_improvments -
        JSON.parse(localStorage.userInfo).nexlaw_credits.used
          .contract_improvments,

      contract_questions_full: JSON.parse(localStorage.userInfo).nexlaw_credits
        .full.contract_questions,
      contract_questions_remain:
        JSON.parse(localStorage.userInfo).nexlaw_credits.full
          .contract_questions -
        JSON.parse(localStorage.userInfo).nexlaw_credits.used
          .contract_questions,

      htmlContent: "",
      htmlArray: [],
      currentBlock: null,
      egVar: null,
      modifiedContent: "",
      suggestionPrompt: "",
      currentGenEg: "",

      // general regex
      // replacement required before use
      regexContent: `<span id="CLAUSE-[INDEX]-" class="CRE-content-block"[^>]*?>(<b>(Example|Improved Clause|Custom Improvement): <\/b>[ESCCLAUSE]|.*?)<\/span>`,
    };
  },

  computed: {
    currentBlockObj() {
      return this.htmlArray.find(
        (el) => el?.content?.includes(`CLAUSE-${this.currentBlock}-`) ?? null
      );
    },
  },

  methods: {
    exportData(data) {
      var source = null;
      if (this.contractType === "html") {
        var header =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
          "xmlns:w='urn:schemas-microsoft-com:office:word' " +
          "xmlns='http://www.w3.org/TR/REC-html40'>" +
          `<head><meta charset='utf-8'><title></title></head><meta name="description" content=""><body>`;
        var footer = "</body></html>";
        source =
          header +
          document
            .getElementById("htmlContentEditor")
            .innerHTML.replaceAll("\n", "<br>") +
          footer;

        source = source
          .replace(
            /class="CRE-suggestion-block"[^<]*/g,
            `style="background-color: orange"`
          )
          .replace(
            /class="CRE-content-block"[^<]*/g,
            `style="background-color: yellow"`
          );
      }

      if (source && data == "DOCUMENT") {
        source =
          "data:application/vnd.ms-word;charset=utf-8," +
          encodeURIComponent(source);
        var fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = "document.doc";
        fileDownload.click();
        document.body.removeChild(fileDownload);

        this.$toast.success("Successfully downloaded Document");
      }
      // // need to remove all original styles or font error will be occured
      // else if (source && data === "PDF") {
      //   ExportDataPDF(source.replace(/id="[^"]*"/g, ''));
      //   this.$toast.success("Successfully downloaded PDF");
      // }
    },

    saveContractChanges() {
      const doc = new DOMParser().parseFromString(
        `<html>${
          document.getElementById("htmlContentEditor").innerHTML
        }</html>`,
        "text/html"
      );

      ContractReview.SaveContractChanges(this.$route.params.id, {
        content: doc.documentElement.innerHTML,
      })
        .then((res) => {
          this.$toast.success("Saved");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    backtosuggestions() {
      this.selectedTab = "aiSug";
    },

    // handle AI suggestions //

    clickCREBlock(event) {
      var EL = null;
      // clicked on content block
      if (event.target.id.includes("CLAUSE-")) {
        EL = event.target;

        // clicked on original block
      } else if (
        event.target.parentElement.parentElement.id.includes("CLAUSE-")
      ) {
        EL = event.target.parentElement.parentElement;
      }

      if (EL) {
        var INDEX = parseInt(EL.id.replace("CLAUSE", "").replace(/-/g, ""));
        this.accessAISuggestions(INDEX);
        this.currentBlock = INDEX;
      }
    },

    clickSuggestionCard(index) {
      this.handleCurrentGen();
      this.currentBlock = index;
      if (this.contractType === "pdf") return;

      // apply border to the correct element
      document.querySelectorAll(`[id^=CLAUSE-]`).forEach((el) => {
        el.style.cssText =
          el.id === `CLAUSE-${index}-` ? "border: 0.1vw solid blue" : "";
      });

      // scroll to targeted original-content
      document.querySelectorAll(`[id^=CLAUSE-${index}-]`)[0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },

    accessAISuggestions(index) {
      this.clickSuggestionCard(index);

      this.selectedTab = "showSuggestions";
      this.suggestionPrompt = "";
      this.currentGenEg = "";

      if (this.contractType === "pdf") return;

      this.egVar = null;
      const imp = this.currentBlockObj.clause.imp;
      if (imp.length) {
        const CONTENT = document.getElementById("htmlContentEditor").innerHTML;
        const index = imp.findIndex((item) => CONTENT.includes(item));
        this.egVar = index !== -1 ? index : null;
      }

      this.updateModifiedContent();
    },

    handleCurrentGen() {
      if (
        this.currentBlock === null ||
        this.contractType === "pdf" ||
        this.currentGenEg.length === 0
      )
        return;

      const clause = this.currentBlockObj.clause;
      if (!clause.imp.length) {
        clause.imp = [this.currentGenEg];
      } else {
        clause.imp.push(this.currentGenEg);
      }
      this.currentGenEg = "";
    },

    updateModifiedContent() {
      const obj = this.currentBlockObj;
      this.modifiedContent = obj.content
        .match(
          new RegExp(
            this.regexContent
              .replace("[INDEX]", this.currentBlock)
              .replace("[ESCCLAUSE]", escapeRegExp(obj.clause.text))
          )
        )[1]
        .replace(/<b>(Example|Improved Clause|Custom Improvement): <\/b>/g, "");
    },

    // Wrapper func for updateContent
    replaceContent(flag, idx = null) {
      if (flag === "mod") {
        // using < > will result in wrapping the text in custom html tag,
        // which require complex handling for the content & affect the overall stability.
        // thus, these symbols are not allowed.
        this.modifiedContent = this.modifiedContent.replace(/<|>/g, "");
      }

      if (["undo", "mod"].includes(flag)) this.egVar = null;

      this.updateContent(
        flag === "undo"
          ? this.currentBlockObj.clause.ex
          : flag === "imp"
          ? this.currentBlockObj.clause.imp[idx]
          : flag === "mod"
          ? this.modifiedContent
          : this.currentGenEg,
        flag
      );
      this.updateModifiedContent();
    },
    updateContent(replacement, flag) {
      const obj = this.currentBlockObj;
      const EX = flag === "undo" || replacement == obj.clause.ex;

      obj.content = obj.content.replace(
        new RegExp(
          this.regexContent
            .replace("[INDEX]", this.currentBlock)
            .replace("[ESCCLAUSE]", escapeRegExp(obj.clause.text))
        ),
        (match, p1) =>
          match.replace(
            p1,
            `<b>${EX ? "Improved Clause" : "Custom Improvement"}: </b>${replacement}`
          )
      );
    },
    // // // //

    generateContent() {
      function updateStorage() {
        me.isGenerating[index] = false;
        store = JSON.parse(localStorage.getItem("CRGenerating"));
        var item = null;
        const notGenerating = !Object.entries(me.isGenerating)
          .map((pair) => pair[1])
          .includes(true);
        if (notGenerating && store.length === 1) {
          localStorage.removeItem("CRGenerating");
        } else if (notGenerating) {
          item = JSON.stringify(
            store.filter((obj) => obj.id !== me.$route.params.id)
          );
        } else {
          let id = store.findIndex((obj) => obj.id === me.$route.params.id);
          store[id] = { id, status: me.isGenerating };
          item = JSON.stringify(store);
        }

        if (item) localStorage.setItem("CRGenerating", item);
      }

      const index = this.currentBlock,
        prompt = this.suggestionPrompt;

      if (prompt.length === 0) {
        this.$toast.error("Please input valid prompt.");
        return;
      }

      this.$set(this.isGenerating, index, true);

      var store = localStorage.getItem("CRGenerating"),
        CRGarray = JSON.parse(store),
        obj = {
          id: this.$route.params.id,
          status: this.isGenerating,
        };
      if (store) {
        CRGarray.push(obj);
      } else {
        CRGarray = [obj];
      }
      localStorage.setItem("CRGenerating", JSON.stringify(CRGarray));

      const me = this;
      ContractReview.changeClause(
        this.$route.params.id,
        this.currentBlockObj.APID,
        { improvement: prompt }
      )
        .then((response) => {
          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));

            const userInfor = JSON.parse(localStorage.getItem("userInfo"));
            if (
              !userInfor ||
              userInfor.nexlaw_credits == undefined ||
              userInfor.nexlaw_credits == "undefined" ||
              userInfor.nexlaw_credits == null ||
              userInfor.nexlaw_credits == "null"
            ) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              this.$router.push("/login");
            }

            const credits = res.data.data.nexlaw_credits;
            // console.log("userInfo", userInfo)
            this.contract_improvments_full = credits.full.contract_improvments;
            this.contract_improvments_remain =
              credits.full.contract_improvments -
              credits.used.contract_improvments;
          });

          this.handleCurrentGen();

          this.suggestionPrompt = "";
          this.currentGenEg = response.data.data;
          updateStorage();
        })
        .catch((err) => {
          console.log(err);
          updateStorage();
        });

      this.changes = "";
    },

    // Lawbot //

    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },

    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;

      ContractReview.sendQuestion(this.$route.params.id, {
        question: message.text,
      })
        .then((response) => {
          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));

            const userInfor = JSON.parse(localStorage.getItem("userInfo"));
            if (
              !userInfor ||
              userInfor.nexlaw_credits == undefined ||
              userInfor.nexlaw_credits == "undefined" ||
              userInfor.nexlaw_credits == null ||
              userInfor.nexlaw_credits == "null"
            ) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              this.$router.push("/login");
            }

            const credits = res.data.data.nexlaw_credits;
            this.contract_questions_full = credits.full.contract_questions;
            this.contract_questions_remain =
              credits.full.contract_questions - credits.used.contract_questions;
          });
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.calltime("default");
        })
        .catch((err) => {
          this.loadAnswer = false;
          console.log(err);
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight - div.clientHeight;

      var container = document.getElementById("messages");
      container.scrollTop = container.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    calltime(data) {
      var me = this;
      setTimeout(function () {
        if (!me.$route.params.id) return;
        
        ContractReview.ViewContractReview(me.$route.params.id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];

            if (last_q.answer !== null) {
              const InMessage = {
                class_1: "chat__incoming",
                class_2: "chat__bubble__wrapper-incoming",
                class_3: "chat__bubble-incoming animated-background-incoming",
                new: true,
              };

              try {
                JSON.parse(last_q.answer).forEach((ans) => {
                  InMessage.text = ans;
                  me.chatMessages.push(InMessage);
                });
              } catch (error) {
                InMessage.text = last_q.answer;
                me.chatMessages.push(InMessage);
              }

              me.loadAnswer = false;
            } else {
              me.calltime(data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 5000);
    },

    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });
        var toSend = this.NewText;
        this.NewText = "";

        ContractReview.sendQuestion(this.$route.params.id, { question: toSend })
          .then((res) => {
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));

              const nexlaw_credits =
                JSON.parse(localStorage.getItem("userInfo"))?.nexlaw_credits ??
                false;
              if (
                nexlaw_credits ||
                [undefined, "undefined", null, "null"].includes(nexlaw_credits)
              ) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userInfo");
                this.$router.push("/login");
              }

              const credits = res.data.data.nexlaw_credits;
              this.contract_questions_full = credits.full.contract_questions;
              this.contract_questions_remain =
                credits.full.contract_questions -
                credits.used.contract_questions;
            });
            this.calltime("none");
          })
          .catch((err) => {
            this.loadAnswer = false;
            console.log(err);
          });

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight - div.clientHeight;

        var container = document.getElementById("messages");
        container.scrollTop = container.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },
  },

  created() {
    ContractReview.ViewContractReview(this.$route.params.id)
      .then((response) => {
        this.pageLoading = false;
        const DATA = response.data.data;

        this.case_file = DATA.case_file;
        this.contractType = DATA.full_content.type;
        this.htmlContent = DATA.full_content.value
          .replace(/&#xa0;|<\s*img src="" \b[^>]*>/g, "")
          .replace(
            /<span [^<]*orange[^<]*><b>Suggestion: <\/b>.*?<\/span><br><br><span><b>(Example|Custom Improvement): <\/b>.*?<\/span>/g,
            ""
          )
          .replace(/\n|<br><br><br>/g, "<br>");

        // cast raw data to needed format

        const Improvements = DATA.values.reduce((acc, { key, value }) => {
          if (key.includes("improvment")) {
            acc.push(JSON.parse(value));
          }
          return acc;
        }, []);

        const formattedSug = [];
        DATA.values
          .filter((el) => el.key.includes("example"))
          .map(({ id, value }) => {
            const item = { ...JSON.parse(value), id };
            item.Examples = { example: item.Examples };
            const matches = Improvements.filter(
              ({ clause }) =>
                clause.replace(/\n/g, "") === item.Clause.replace(/\n/g, "")
            );
            if (matches.length) {
              item.Examples.improvement = matches.map((item) => item.output);
            }
            return item;
          })
          .forEach((item) => {
            const Clause = item.Clause.replace(/\n/g, "<br>"),
              Sug = item.Suggestions.replace(/\n/g, " "),
              Ex = item.Examples.example.replace(/\n/g, " "),
              Imp =
                item.Examples?.improvement?.map((imp) =>
                  imp.replace(/\n/g, " ")
                ) ?? [],
              preIndex = formattedSug.findIndex((pre) => pre.Clause === Clause),
              SugArr = [];

            // preprocess suggestions
            if (Sug.startsWith("1. ")) {
              SugArr.push(Sug.split("1. ")[1]);

              var count = 2;
              SugArr.forEach(sug => {
                SugArr.splice(count - 2);
                SugArr.push(...sug.split(`${count}. `));
                count++;
              })
            } else {
              SugArr.push(Sug);
            }

            if (preIndex >= 0) {
              const precedent = formattedSug[preIndex],
                preEx = precedent.Examples,
                preSug = precedent.Suggestions;

              // handle suggestions
              if (preSug !== Sug) {
                if (preSug.startsWith("1. ")) {
                  SugArr.push(preSug.split("1. "));

                  var count = 2;
                  SugArr.forEach(sug => {
                    SugArr.splice(count - 2);
                    SugArr.push(...sug.split(`${count}. `));
                    count++;
                  })
                } else {
                  SugArr.push(preSug);
                }
                
                preSug = SugArr.map(
                  (sug, index) => `${index + 1}. ${sug}`
                ).join("<br>");
              }
              // handle examples
              if (preEx.example !== Ex) {
                if (preEx.improvement && !preEx.improvement.includes(Ex)) {
                  preEx.improvement.push(Ex);
                } else if (!preEx.improvement) {
                  preEx.improvement = [Ex];
                }
              }
              // handle any extra improvements
              preEx.improvement = Array.from(
                new Set(preEx.improvement.concat(Imp))
              );
            } else {
              item.Clause = Clause;
              item.Suggestions = SugArr.map(
                (sug, index) => `${index + 1}. ${sug}`
              ).join("<br>");
              item.Examples.example = Ex;
              item.Examples.improvement = Imp;
              formattedSug.push(item);
            }
          });

        // split content into block array
        if (this.contractType === "html") {
          formattedSug.forEach((item, index) => {
            const Clause = item.Clause,
              Sug = item.Suggestions,
              Ex = item.Examples.example,
              Imp = item.Examples.improvement,
              escClause = escapeRegExp(Clause),
              escSug = escapeRegExp(Sug),
              TagID = `id="CLAUSE-${index}-"`,
              ClauseBlock = `<span ${TagID} class="CRE-original-block"><s>${Clause}</s></span>`,
              SugBlock = `<span ${TagID} class="CRE-suggestion-block"><b>Suggestion: </b>${Sug}</span>`,
              regexClause = `<span ${TagID} class="CRE-original-block"[^>]*?><s>.*?<\/s><\/span>`,
              regexSug = `<span ${TagID} class="CRE-suggestion-block"[^>]*?><b>Suggestion: <\/b>(${escSug}|.*?)</span>`,
              regexContent = this.regexContent
                .replace("[INDEX]", index)
                .replace("[ESCCLAUSE]", escClause);

            var CURRENT = null,
              blockId = null,
              splitted = [];

            // assign the 1st obj in first iteration
            if (this.htmlArray.length === 0) {
              this.htmlArray = [
                {
                  content: this.htmlContent,
                },
              ];
            }

            // split matching clause in the htmlArray
            this.htmlArray.some((block, idx) => {
              if (
                block.content.includes(SugBlock) || // handle processed document
                block.content.includes(Clause) // handle new document
              ) {
                blockId = idx;
                splitted = block.content
                  .replace(
                    new RegExp(
                      `${regexClause}<br><br>${regexSug}<br><br>${regexContent}`
                    ),
                    (match, p1, p2) => {
                      CURRENT = p2.replace(/<br>/g, " ");

                      return `[[!!SPLIT!!]]`;
                    }
                  )
                  .split(CURRENT ? `[[!!SPLIT!!]]` : Clause);
                return true;
              }
              return false;
            });

            // detect matching Clause
            if (splitted.length === 2) {
              const splitted1 = { content: splitted[0] },
                splitted2 = { content: splitted[1] },
                matching = {
                  APID: item.id,
                  id: index,
                  found: true,
                  content: `${ClauseBlock}<br><br>${SugBlock}<br><br><span ${TagID} class="CRE-content-block">${
                    CURRENT ?? `<b>Improved Clause: </b>${Ex}`
                  }</span>`,
                  clause: {
                    text: Clause,
                    sug: item.Suggestions,
                    ex: Ex,
                    imp: Imp ?? [],
                  },
                };

              // update the array
              if (blockId === null) {
                this.htmlArray = [
                  ...this.htmlArray,
                  splitted1,
                  matching,
                  splitted2,
                ];
              } else {
                this.htmlArray.splice(
                  blockId,
                  1,
                  splitted1,
                  matching,
                  splitted2
                );
              }
              item.found = true; // [!!]
            } else {
              // for debugging
              // console.log("Undetected: ", index);
            }
          });
          // console.log(
          //   formattedSug.length,
          //   this.htmlArray.filter((block) => block.clause)
          // );
          // console.log(formattedSug);

          // no additional pre-process for pdf document
        } else if (this.contractType === "pdf") {
          this.htmlArray = formattedSug;
        }

        const OutMessage = {
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        };
        const InMessage = {
          class_1: "chat__incoming",
          class_2: "chat__bubble__wrapper-incoming",
          class_3: "chat__bubble-incoming animated-background-incoming",
          new: false,
        };
        DATA.questions.forEach((el) => {
          if (el.answer.length) {
            const dQindex = this.default_questions.findIndex(
              (x) => x === el.question
            );
            if (dQindex !== -1) {
              this.default_questions.splice(dQindex, 1);
            }

            OutMessage.text = el.question;
            this.chatMessages.push({...OutMessage});

            try {
              JSON.parse(el.answer).forEach((answer) => {
                InMessage.text = answer;
                this.chatMessages.push({...InMessage});
              });
            } catch (error) {
              InMessage.text = el.answer;
              this.chatMessages.push({...InMessage});
            }
          }
        });

        if (
          DATA.questions.length &&
          DATA.questions[DATA.questions.length - 1].answer == null
        ) {
          this.loadAnswer = true;
          this.calltime("none");

          OutMessage.text = DATA.questions[DATA.questions.length - 1].question;
          this.chatMessages.push(OutMessage);
        }

        if (this.chatMessages.length == 0) {
          InMessage.text = "You may ask question about your contract here🔎";
          this.chatMessages.push(InMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  mounted() {
    setTimeout(() => {
      this.entered = true;
    }, 1000);
  },
};
</script>

<style scoped>
/* ----- chatbot ----- */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
}

.chat__input .btn-send:hover span {
  color: #004278;
  transition: 300ms ease-out;
}

/* ------------------- */
.container_margin {
  padding: 1rem 2rem;
}

.card_caseDoc {
  height: 770px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.card_question {
  height: 590px;
}

.card_genEg {
  /* height: 200px;
  max-height: 200px; */
  overflow-x: hidden;
  overflow-y: scroll;
}

/*.popover {
    max-width: 500px !important;
    background: #094890;
  }

  .popover_container h5 {
    color: white;
  }

  @media only screen and (max-width: 768px) {
    .floating-menu {
      bottom: 20px !important;
      right: 720px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }

  @media only screen and (max-width: 425px) {
    .floating-menu {
      bottom: 20px !important;
      right: 378px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }

  @media only screen and (max-width: 375px) {
    .floating-menu {
      bottom: 20px !important;
      right: 328px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }

  @media only screen and (max-width: 320px) {
    .floating-menu {
      bottom: 20px !important;
      right: 273px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }

  .floating-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #094890;
    position: fixed;
    bottom: 10px;
    right: 66px;
    width: 50px;
    height: 50px;
    transform: translateY(70px);
    transition: all 250ms ease-out;
    border-radius: 50%;
    opacity: 0;

    border: 2px solid var(--color-primary);
  }

  .floating-menu.chat {
    bottom: 80px;
    right: 80px;
  }

  .floating-menu.enter:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    opacity: 1;
  }

  .floating-menu.enter {
    transform: translateY(0);

    opacity: 1;
    background: white;

    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
  }

  .floating-menu.expand {
    width: 210px;
    max-height: 350px;
    height: 240px;
    border-radius: 5px;
    cursor: auto;
    opacity: 1;
    background: white;
  }

  .floating-menu.expand .icon-robot {
    display: none;
  }

  .floating-menu :focus {
    outline: 0;
  }

  .floating-menu button {
    background: #2c8ee1;
    border-radius: 20px;
    font-size: 10px;
    border: 0;
    color: grey;
    text-transform: uppercase;
    cursor: pointer;
  }

  .floating-menu .chat {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 50%;
    transition: all 350ms ease-out;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  .floating-menu .chat.enter {
    opacity: 1;
    border-radius: 0;
    margin: 10px;
    width: auto;
    height: auto;
  }

  .floating-menu .chat .messages {
    padding: 3rem 10px 0px;
    margin: 0;
    list-style: none;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
    border-radius: 4px;
    background: transparent;
  }

  .floating-menu .chat .messages::-webkit-scrollbar {
    width: 5px;
  }

  .floating-menu .chat .messages::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
  }

  .floating-menu .chat .messages::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #2c8ee1 !important;
    border: none;
  }

  .floating-menu .chat .messages li {
    position: relative;
    clear: both;
    display: inline-block;
    padding: 14px;
    margin: 0 0 20px 0;
    font: 12px/16px "Noto Sans", sans-serif;
    border-radius: 10px;
    background-color: rgba(246, 248, 250, 0.836);
    word-wrap: break-word;
    max-width: 81%;
    border: none;
  }

  .floating-menu .chat .messages li:before {
    position: absolute;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    content: "";
    background-size: cover;
  }

  .floating-menu .chat .messages li:after {
    position: absolute;
    top: 10px;
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid rgba(246, 248, 250, 0.836);
    border-right: 1px solid rgba(246, 248, 250, 0.836);
  }

  .floating-menu .chat .messages li.other {
    animation: show-chat-odd 0.15s 1 ease-in;
    -moz-animation: show-chat-odd 0.15s 1 ease-in;
    -webkit-animation: show-chat-odd 0.15s 1 ease-in;
    float: right;
    margin-right: 45px;
    color: #2c8ee1;
  }

  .floating-menu .chat .messages li.other:before {
    right: -45px;
    background-image: url(https://github.com/Thatkookooguy.png);
  }

  .floating-menu .chat .messages li.other:after {
    border-right: 10px solid transparent;
    right: -10px;
  }

  .floating-menu .chat .messages li.self {
    animation: show-chat-even 0.15s 1 ease-in;
    -moz-animation: show-chat-even 0.15s 1 ease-in;
    -webkit-animation: show-chat-even 0.15s 1 ease-in;
    float: left;
    margin-left: 45px;
    color: #2c8ee1;
  }

  .floating-menu .chat .messages li.self:before {
    left: -45px;
    background-image: url(https://github.com/ortichon.png);
  }

  .floating-menu .chat .messages li.self:after {
    border-left: 10px solid transparent;
    left: -10px;
  }

  .floating-menu .chat .footer {
    flex-shrink: 0;
    display: flex;
    padding-top: 10px;
    max-height: 90px;
    background: transparent;
  }

  .floating-menu .chat .footer .text-box {
    border-radius: 3px;
    background: rgba(246, 248, 250, 0.993);
    min-height: 100%;
    width: 100%;
    margin-right: 5px;
    color: #0ec879;
    overflow-y: auto;
    padding: 2px 5px;
  }

  .floating-menu .chat .footer .text-box::-webkit-scrollbar {
    width: 5px;
  }

  .floating-menu .chat .footer .text-box::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(25, 147, 147, 0.1);
  }

  .floating-menu .chat .footer .text-box::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(25, 147, 147, 0.2);
  } */

.text-primary {
  color: var(--color-primary) !important;
}

.card_bot_question {
  height: 770px;
  overflow-y: scroll;
}

.highlight-card {
  border-width: 2px;
  border-color: blue;
}

span {
  text-align: justify;
}

.span_badge {
  border: none;
  color: black;
  border-radius: 20px;
  font-size: 17px;
  padding: 0.1rem 0.1rem;
}
</style>

<style>
.CRE-original-block {
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border-color: blue;
  }
}

.CRE-content-block {
  cursor: pointer;
  background-color: yellow;
  border: 1px solid transparent;

  &:hover {
    border-color: blue;
  }
}

.CRE-suggestion-block {
  cursor: pointer;
  background-color: orange;
  border: 1px solid transparent;

  &:hover {
    border-color: blue;
  }
}

s {
  text-decoration-color: red;
}
</style>
